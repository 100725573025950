<template>
    <div>
        <b-row>
            <b-col class="text-center">
                <label class="switch">
                    <input type="checkbox" v-model="view" />
                    <span class="slider round">
                        <feather-icon
                            icon="CalendarIcon"
                            size="20"
                            :class="`float-left mt-1 zindex-2 position-relative ${
                                !view
                                    ? 'animate__animated animate__zoomIn'
                                    : 'animate__animated animate__zoomOut'
                            }`"
                            style="margin-left: 18px"
                        ></feather-icon>
                        <feather-icon
                            icon="CheckSquareIcon"
                            size="20"
                            :class="`float-right mt-1 zindex-2 position-relative ${
                                view
                                    ? 'animate__animated animate__zoomIn'
                                    : 'animate__animated animate__zoomOut'
                            }`"
                            style="margin-right: 18px"
                        ></feather-icon>
                    </span>
                </label>
            </b-col>
        </b-row>
        <check-list v-if="view" />
        <calendario v-if="!view" />
    </div>
</template>
<script>
import { ref, reactive, onMounted } from "@vue/composition-api";
import CheckList from "./components/CheckList.vue";
import Calendario from "./components/Calendario.vue";

export default {
    components: {
        CheckList,
        Calendario,
    },
    setup(props, context) {
        const view = ref();

        return { view };
    },
};
</script>
<style lang="css" scope>
/* para el switch*/
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 50px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 35px;
    width: 40px;
    left: 8px;
    bottom: 8px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
}

input:focus + .slider {
    box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
    -webkit-transform: translateX(64px);
    -ms-transform: translateX(64px);
    transform: translateX(64px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
