<template>
    <div class="p-2">
        <div class="d-flex justify-content-between">
            <h2 class="mb-1">Checklist de actividades</h2>

            <div>
                <b-button
                    variant="relief-success"
                    size="sm"
                    pill
                    class="py-50 mr-1"
                    @click="abrirSidebarCrearActividad"
                    >Crear actividad
                </b-button>
                <b-button
                    variant="relief-primary"
                    size="sm"
                    class="py-50 rounded-pill"
                >
                    Historial
                </b-button>
            </div>
        </div>

        <b-row>
            <b-col>
                <img
                    src="/img/general/pin_success.png"
                    alt="Pin"
                    class="position-absolute zindex-1 pin animate__animated animate__tada"
                />
                <b-card>
                    <b-card-title class="mt-1"
                        >Tareas pendientes por realizar</b-card-title
                    >
                    <b-card-body
                        class="border round p-1 overflow-auto"
                        style="height: calc(100vh - 60vh)"
                    >
                        <div>
                            <b-form-checkbox
                                v-for="(tarea, index) in tareasPendientes"
                                :key="index"
                                v-model="tarea.checked"
                                button-variant="dark"
                                class="my-1 border-bottom custom-control-success"
                                :style="tarea.checked ? 'opacity:0.5;' : ''"
                            >
                                <b-row>
                                    <b-col cols="7" style="width: 300px">
                                        <p
                                            class="font-weight-bold mb-50 tres-puntos"
                                        >
                                            {{ tarea.titulo }}
                                        </p>
                                        <p class="tres-puntos">
                                            {{ tarea.descripcion }}
                                        </p>
                                    </b-col>
                                    <b-col cols="5">
                                        <p class="mb-50">
                                            <feather-icon icon="CalendarIcon">
                                            </feather-icon>
                                            Viernes 23 sep. 2022
                                        </p>
                                        <p>
                                            <feather-icon
                                                icon="ClockIcon"
                                            ></feather-icon>
                                            12:00 pm
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-form-checkbox>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col>
                <img
                    src="/img/general/pin_danger.png"
                    alt="Pin"
                    class="position-absolute zindex-1 pin animate__animated animate__tada"
                />
                <b-card>
                    <b-card-title class="mt-1">Tareas vencidas</b-card-title>
                    <b-card-body
                        class="border round p-1 overflow-auto"
                        style="height: calc(100vh - 60vh)"
                    >
                        <div>
                            <b-form-checkbox
                                v-for="(tarea, index) in tareasPendientes"
                                :key="index"
                                v-model="tarea.checked"
                                button-variant="dark"
                                class="my-1 border-bottom custom-control-danger"
                                :style="tarea.checked ? 'opacity:0.5;' : ''"
                            >
                                <b-row>
                                    <b-col cols="7" style="width: 300px">
                                        <p
                                            class="font-weight-bold mb-50 tres-puntos"
                                            v-b-tooltip.hover.top="tarea.titulo"
                                        >
                                            {{ tarea.titulo }}
                                        </p>
                                        <p
                                            class="tres-puntos"
                                            v-b-tooltip.hover.top="
                                                tarea.descripcion
                                            "
                                        >
                                            {{ tarea.descripcion }}
                                        </p>
                                    </b-col>
                                    <b-col cols="5">
                                        <p
                                            class="mb-50 text-danger font-weight-bold"
                                        >
                                            <feather-icon icon="CalendarIcon">
                                            </feather-icon>
                                            Viernes 23 sep. 2022
                                        </p>
                                        <p class="text-danger font-weight-bold">
                                            <feather-icon
                                                icon="ClockIcon"
                                            ></feather-icon>
                                            12:00 pm
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-form-checkbox>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <!-- sidebar crear actividad -->
        <sidebar-crear-actividad
            ref="refSidebarCrearActividad"
        ></sidebar-crear-actividad>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    components: {
        SidebarCrearActividad: () =>
            import("../sidebars/SidebarCrearActividad.vue"),
    },
    setup(props, context) {
        const tareasPendientes = ref([
            {
                titulo: "Cita con Javier Gonzales",
                descripcion: "Paciente con reflujo constante",
                checked: false,
            },
            {
                titulo: "Cita con Oswaldo Herrera",
                descripcion: "Paciente con incapacidad de soñar",
                checked: false,
            },
            {
                titulo: "Cita con Ximena Lopez",
                descripcion: "Paciente con dolor en el corazón",
                checked: false,
            },
            {
                titulo: "Informe semanal",
                descripcion: "Reunión presentación de rendimientos",
                checked: false,
            },
        ]);

        function abrirSidebarCrearActividad() {
            context.refs.refSidebarCrearActividad.toggle();
        }

        return { tareasPendientes, abrirSidebarCrearActividad };
    },
};
</script>
<style lang="css" scope>
.pin {
    height: 40px;
    width: 50px;
    left: 0;
    top: 1;
}
</style>
