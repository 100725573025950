<template>
    <div class="container">
        <div class="row mt-2">
            <div class="col-auto">
                <h4>Filtro</h4>
                <!-- <b-button variant="outline-primary" class="mb-2"
                    >Agregar evento</b-button
                > -->
                <b-form-checkbox
                    v-for="(check, index) in filterCheckbox"
                    :key="index"
                    :class="`custom-control-${check.color} my-1`"
                    >{{ check.nombre }}</b-form-checkbox
                >
            </div>
            <div class="col">
                <full-calendar :options="calendarOptions" ref="calendar" />
            </div>
        </div>
    </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";

import interactionPlugin from "@fullcalendar/interaction";

import { ref, reactive, onMounted } from "@vue/composition-api";
export default {
    components: {
        FullCalendar,
    },
    setup(props, context) {
        const filterCheckbox = ref([
            {
                nombre: "Asistencias",
                color: "primary",
            },
            {
                nombre: "Checklist",
                color: "warning",
            },
        ]);
        const calendarOptions = reactive({
            locale: esLocale,
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin, // needed for dateClick
            ],
            initialView: "dayGridMonth",
            weekends: true, // initial value
            height: "auto",
            headerToolbar: {
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
            },
            events: [
                {
                    title: "Estudiar",
                    date: "2022-09-08",
                    className: "bg-gradient-primary border-primary",
                },
                {
                    title: "Hacer ejercicio",
                    date: "2022-09-09",
                    className: "bg-gradient-danger border-danger",
                },
                {
                    title: "Dejar plantada a Stefanya",
                    date: "2022-09-10",
                    className: "bg-gradient-success border-success",
                },
                {
                    title: "Salir con mi ex <3",
                    date: "2022-09-10",
                    className: "bg-gradient-warning border-warning",
                },
            ],
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: 5,
            select: handleDateSelect,
            eventClick: handleEventClick,
            eventsSet: handleEvents,
        });

        function handleDateSelect($event) {
            console.log("select", $event);
        }

        function handleEventClick($event) {
            console.log("event click", $event);
        }

        function handleEvents($events) {
            console.log("events", $events);
        }

        onMounted(() => {
            console.log(context.refs.calendar);
        });

        return {
            calendarOptions,
            handleDateSelect,
            handleEventClick,
            handleEvents,
            filterCheckbox,
        };
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
